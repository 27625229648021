import React from 'react';
import PropTypes from 'prop-types'
import logo from './logo.png';
import { Container, ListGroup } from 'react-bootstrap';
import Webtest from './Webtest';
import Dropbox from './Dropbox';

class App extends React.Component {

  static defaultProps = {
    tools: [
      { "name": "tool" }
    ]
  }

  state = {
    currentTool: ''
  }

  setTool = (event) => {
    this.setState({
      currentTool: event
    })
  }

  displayTool = (tool) => {
    switch (tool) {
      case "Website Test":
        return <Webtest />
      case "Dropbox Direct Link":
        return <Dropbox />
      default:
        break;
    }
  }

  render() {
    const { tools } = this.props
    return (
      <Container className="text-center">
        <img className="m-4" src={logo} alt="" width="72" height="72" />
        <h1 className="mb-4">Alhasan Tools</h1>
        <ListGroup className="mb-4" aria-label="Basic example">
          {tools.map((tool, i) =>
            <ListGroup.Item action as="button" onClick={(e) => this.setTool(tool.name, e)} key={i}>
              {tool.name}
            </ListGroup.Item>
          )}
        </ListGroup>
        {this.displayTool(this.state.currentTool)}
      </Container>
    )
  }
}

App.propTypes = {
  tools: PropTypes.array
}

export default App;