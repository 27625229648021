import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Form, Alert } from 'react-bootstrap';

class Dropbox extends React.Component {

    state = {
        input: '',
        result: ''
    }

    validURL = (url) => {
        url = url.toLowerCase()
        let regExURL = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w.-]+)+[\w\-._~:/?#[\]@!$&'()*+,;=.]+$/
        let extractURL = /^(?:https?:\/\/)?(?:[^@\n]+@)?(?:www\.)?([^:/\n?=]+)/im
        let test = regExURL.test(url)
        test = (test && (extractURL.exec(url)[1]).toLowerCase() === "dropbox.com")
        return test ? (url.replace("//www","//dl")).replace("dropbox.com","dropboxusercontent.com"): ""
    }

    handleChangedInput = (event) => {
        this.setState({
            input: event.target.value,
            result: this.validURL(event.target.value)
        })
    }

    render() {
        return (
            <Container className="text-center">
                <h2 className="text-primary mb-4">Dropbox Direct Link</h2>
                <Form>
                    <Form.Group controlId="formBasicWeb">
                        <Form.Control type="text" placeholder="Dropbox URL" autoFocus="" onChange={this.handleChangedInput} value={this.state.input} />
                        <Form.Text className="text-left text-muted">
                            For example: https://www.dropbox.com/s/<strong>zrrg19wp3xhcd70</strong>/<strong>logo.png</strong>?dl=0
                        </Form.Text>
                    </Form.Group>
                </Form>
                {this.state.result === "" ? "" :
                    <Alert  variant="success"><Alert.Link target="_blank" rel="noopener noreferrer" href={this.state.result}>{this.state.result}</Alert.Link></Alert>
                }
            </Container>
        );
    }
}

export default Dropbox;