import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Form, ListGroup } from 'react-bootstrap';

// Add copy url for other tools

let tools = [
    { "name": "Whatruns", "url": "https://www.whatruns.com/website/" },
    { "name": "Builtwith", "url": "https://builtwith.com/" },
    { "name": "Rescan", "url": "https://rescan.io/analysis/" },
    { "name": "Whatcms", "url": "https://whatcms.org/?s=" },
    { "name": "Who Hosts This", "url": "https://www.who-hosts-this.com/?s=" },
    { "name": "Wordpress Theme Detect", "url": "https://www.themedetect.com/?s=" },
    { "name": "Wappalyzer", "url": "https://api.wappalyzer.com/lookup-basic/v1/?url=https://" },
    { "name": "Cubdomain", "url": "https://www.cubdomain.com/site/" },
    { "name": "Email Hunter", "url": "https://hunter.io/try/search/" },
    { "name": "Check Website Online", "url": "https://checkwebsiteonline.com/domain/" }
]

class Webtest extends React.Component {

    state = {
        input: '',
        result: ''
    }

    validURL = (url) => {
        let regExURL = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w.-]+)+[\w\-._~:/?#[\]@!$&'()*+,;=.]+$/
        let extractURL = /^(?:https?:\/\/)?(?:[^@\n]+@)?(?:www\.)?([^:/\n?=]+)/im
        let test = regExURL.test(url)
        return test ? extractURL.exec(url)[1] : ""
    }

    handleChangedInput = (event) => {
        this.setState({
            input: event.target.value,
            result: this.validURL(event.target.value)
        })
    }

    render() {
        return (
            <Container className="text-center">
                <h2 className="text-primary mb-4">Website Test</h2>
                <Form>
                    <Form.Group controlId="formBasicWeb">
                        <Form.Control type="text" placeholder="Website URL" autoFocus="" onChange={this.handleChangedInput} value={this.state.input} />
                        <Form.Text className="text-left text-muted">
                            For example: https://example.com
                        </Form.Text>
                    </Form.Group>
                </Form>
                {this.state.result === "" ? "" :
                    <div>
                        <h2>Tools</h2>
                        <ListGroup className="mb-4">
                            {tools.map((tool, i) =>
                                <ListGroup.Item action target="_blank" rel="noopener noreferrer" href={tool.url + this.state.result} key={i}>
                                    {tool.name}
                                </ListGroup.Item>
                            )}
                        </ListGroup>
                    </div>
                }
            </Container>
        );
    }
}

export default Webtest;